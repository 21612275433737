
.view-ticket-details {
  padding-bottom: 24px;

  .carousel-not-available {
    height: 200px;
    align-items: center;
  }

  .col {
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .carousel {
    position: relative;
    margin-top: 16px;
    margin-bottom: 20px;

    .arrows {
      position: absolute;
      font-size: 28px;
      cursor: pointer;
    }

    .arrow-left,
    .arrow-right {
      top: 50%;
      transform: translateY(-50%);
    }

    .arrow-right {
      right: 0;
    }

    .arrow-left {
      left: 0;
    }

    .carousel-img {
      height: 200px;
      width: 270px;
      object-fit: cover;
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    margin: 0;
  }

  .el-carousel__item {
    height: 200px;
    width: 270px;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}

.status-wrapper {
  display: flex;
  align-items: center;
}



.newWindow6 {
     position: relative;
     display: inline-block;
     border: 1px solid;
     width: 8px;
     height: 8px;
}

.newWindow6:after {
     position: absolute;
     top: -8px;
     right: -3px;
     content: "\1f855";
     /*      content:"\2197"; */
     /*       content:"\2b67";*/
     font-size: 0.8em;
}
.newWindow6:before {
     position: absolute;
     top: -3px;
     right: -3px;
     content: " ";
     border: 4px solid white;
}

.newWindow {
     background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==)
          no-repeat center;
     width: 1em;
     height: 1em;
}

a[target="_blank"] {
     position: relative;
}

a[target="_blank"]:after {
     position: absolute;
     top: 3px;
     right: -15px;
     content: "\1f855";
     font-size: 13px;
     color: black;
     line-height: 3px;
     height: 5px;
     width: 5px;
     border-right: 2px solid white;
     border-top: 2px solid white;
}
a[target="_blank"]:before {
     position: absolute;
     top: 4px;
     right: -15px;
     content: " ";
     border: 1px solid black;
     width: 8px;
     height: 8px;
}


.newWindow3 {
     position: relative;
     border: 1px solid;
     width: 8px;
     height: 8px;
}

.newWindow3:after {
     position: absolute;
     top: -10px;
     right: -4px;
     /*      content:"\2197"; */
     content: "\1f855";
     /*       content:"\2b67";
           font-size:1.1em */
}
.newWindow3:before {
     position: absolute;
     top: -3px;
     right: -3px;
     content: " ";
     border: 4px solid white;
}

.newWindow4 {
     position: relative;
     border: 1px solid;
     width: 8px;
     height: 8px;
}

.newWindow4:after {
     position: absolute;
     top: -7px;
     right: -2px;
     /*      content:"\2197"; */
     content: "\1f855";
     /*       content:"\2b67";*/
     font-size: 0.8em;
}
.newWindow4:before {
     position: absolute;
     top: -3px;
     right: -3px;
     content: " ";
     border: 4px solid white;
}

.newWindow5 {
     position: relative;
     border: 1px solid;
     width: 8px;
     height: 8px;
}

.newWindow5:after {
     position: absolute;
     top: -9px;
     right: -2px;
     /*      content:"\2197"; */
     content: "\2197";
     /*       content:"\2b67";*/
     font-size: 0.9em;
}
.newWindow5:before {
     position: absolute;
     top: -4px;
     right: -4px;
     content: " ";
     border: 4px solid white;
}
